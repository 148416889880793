import styled, { css } from 'styled-components'
import { FocusEffect } from '../styledWrappers'

export const ButtonEffect = css`
  color: #ffffff;
  background: ${props => props.theme.platformColor};
  border-radius: 100vw;
  border: none;

  &:hover {
    background-color: ${props => props.theme.platformColorLight};
    transition: background-color 150ms;
  }

  ${props => !props.noFocusEffect && FocusEffect}
`

export const Button = styled.button`
  ${ButtonEffect}
`

export const ActionButton = styled.button`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 3.5px;
  color: #ffffff;
  border-radius: 100vw;
  padding: 12px 16px;
  text-transform: uppercase;
  height: 37px;
  border: none;
  ${props => props.disabled && props.theme.disableBlock};
  ${ButtonEffect}
  background: ${props => props.theme.buttonGradientBakground};
`

export const SmallButton = styled.button`
  font-family: ${props => props.theme.fontFamilyInter};
  font-weight: medium;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0;
  text-transform: uppercase;
  background-color: ${props => props.theme.platformColor};
  ${ButtonEffect}
  margin-left: 24px;
`

export const QuestionButton = styled.button`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: ${props => props.theme.yellowGradient};
  outline: none;
  border: 2px solid ${props => props.theme.color.white};
  transition: all 0.2s ease;

  position: relative;
  &::before {
    position: absolute;
    content: '?';
    top: -4px;
    left: 30%;
    font-size: 23px;
    font-weight: 900;
    color: ${props => props.theme.color.white};
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:active {
    border-color: rgba(255, 255, 255, 0.5);
    &::before {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  &:hover {
    box-shadow: 0px 0px 10px 10px #ffcd40;
  }
`

export const OptionsButton = styled(Button)`
  background: ${props => props.theme.color.white};
  color: ${props =>
    props.isDisabled
      ? props.theme.color.midGrey
      : props.theme.pickColorDependOnPlatform(props.userindex).primary};
  border: 1px solid
    ${props =>
      props.isDisabled
        ? props.theme.color.midGrey
        : props.theme.pickColorDependOnPlatform(props.userindex).light};
  font-weight: 800;
  display: flex;
  align-items: center;
  font-size: 14px;
  ${props =>
    props.isActive &&
    `
    background-color: ${
      props.isDisabled
        ? props.theme.color.midGrey
        : props.theme.pickColorDependOnPlatform(props.userindex).primary
    };
    color: ${props.theme.color.white};
    & svg {
      path {
        fill: ${props.theme.color.white};
      }
    }
  `}
  & > * {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &:active {
    border-color: ${props =>
      props.theme.pickColorDependOnPlatform(props.userindex).primary};
    box-shadow: 0 0 0 1px
      ${props => props.theme.pickColorDependOnPlatform(props.userindex).primary};
    outline: none;
  }
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${props =>
      props.theme.pickColorDependOnPlatform(props.userindex).primary};
    color: ${props => props.theme.color.white};
    & svg {
      path {
        fill: ${props => props.theme.color.white};
      }
    }
  }
`

export const GradientButton = styled.button.attrs(({ isDisabled }) => ({
  disabled: isDisabled,
}))`
  padding: 10px 15px;
  border-radius: 9px;
  border: none;
  font-family: ${props => props.theme.fontFamilyInter};
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 15px;
  color: ${props => props.theme.color.white};
  user-select: none;

  ${props => {
    let bg = props.theme.infludataMainGradient
    let bgHover = props.theme.infludataDarkOrangeGradient
    let bgDisabled = props.theme.color.infludataMediumGray

    return `
      background: ${props.isDisabled ? bgDisabled : bg};

      &:hover {
        background: ${props.isDisabled ? bgDisabled : bgHover};
      }
    `
  }}
`

export const TransparentButton = styled.button`
  font-size: 15px;
  line-height: 19px;
  padding: 8px 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.color.infludataOffWhite};
  border: 1px solid ${props => props.theme.color.infludataOffWhite};
  border-radius: 9px;
  background: transparent;
  white-space: nowrap;

  &:hover {
    background: ${props => props.theme.color.infludataOffWhite};
    color: ${props => props.theme.color.primary};
  }
`
