import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import useTranslation from '@hooks/useTranslation'
import LandingLayout from '@layouts/LandingLayout'
import { TransparentButton } from '@common/buttons/styledButtons'

const Page404Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.infludataMainGradient};
  position: relative;
`

const centeredStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const PageNotFoundMessage = styled.h1`
  font-size: 54px;
  line-height: 60px;
  font-weight: bold;
  ${centeredStyles}
  user-select: none;
  text-align: center;
  white-space: nowrap;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataWhiteGradient)};

  ${props => props.theme.media.lessThan('tablet')`
    font-size: 36px;
    line-height: 40px;
  `}

  ${props => props.theme.media.lessThan('mobile')`
    font-size: 26px;
    line-height: 30px;
  `}

  ${props => props.theme.media.lessThan('310px')`
    white-space: unset;
  `}
`

const Code404 = styled.div`
  font-size: 253.2px;
  line-height: 281px;
  font-weight: bold;
  ${centeredStyles}
  user-select: none;
  ${props =>
    props.theme.getTextGradientStyles(
      'linear-gradient(266.18deg, rgba(255, 255, 255, 0.04) 15.36%, rgba(255, 255, 255, 0.22) 81.24%);',
    )};

  ${props => props.theme.media.lessThan('tablet')`
    font-size: 180px;
    line-height: 210px;
  `}

  ${props => props.theme.media.lessThan('mobile')`
    font-size: 140px;
    line-height: 160px;
  `}
`

const GoBackLink = styled(Link)`
  position: absolute;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Page404 = () => {
  const { labelStrings } = useTranslation()

  return (
    <LandingLayout>
      <Page404Wrapper>
        <PageNotFoundMessage>{labelStrings.pageNotFound}!</PageNotFoundMessage>
        <Code404>404</Code404>
        <GoBackLink to='/'>
          <TransparentButton>{labelStrings.goBackHome}</TransparentButton>
        </GoBackLink>
      </Page404Wrapper>
    </LandingLayout>
  )
}

export default Page404
